import * as React from "react";

import Banner from "../components/banner";
import CaseSlider from "../components/case-slider";
import FeaturesSection from "../components/features-section/features-section";
import Layout from "../components/layout";
import QuotesSection from "../components/quotes-section";
import WorkSection from "../components/works-section/work-section";
import { SEO } from "../components/seo";

export const Head = () => <SEO />;

const Home = () => {
  return (
    <Layout>
      <Banner />
      <QuotesSection></QuotesSection>
      <WorkSection></WorkSection>
      <FeaturesSection></FeaturesSection>
      <CaseSlider></CaseSlider>
    </Layout>
  );
};

export default Home;
