import React from "react";

import { ANIM_DELAY } from "../../constants/constants";

import quote from "../../images/quote.png";
import works from "../../images/works-img.png";

const QuotesSection = () => {
  return (
    <section className="workSection">
      <div className="container">
        <img src={quote} alt="quote" />
        <div className="row">
          <div className="col-md-6 col-sm-12 ">
            <div
              className="workSection__quotes"
              data-sal="fade"
              data-sal-delay={ANIM_DELAY}
              data-sal-easing="ease"
            >
              <p className="workSection__quotes-message">
                Our extensive network of SMEs provides the knowledge and
                expertise for your research, business or technical AI programs.
              </p>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <figure
              className="workSection__quotes-figure"
              data-sal="flip-right"
              data-sal-delay={ANIM_DELAY}
              data-sal-easing="ease"
            >
              <img src={works} alt="works" />
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
};
export default QuotesSection;
