import React from "react";
import { Link } from "gatsby";
import Slider from "react-slick";

import tabImage from "../../images/tab-image.png";

const CaseSlider = () => {
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: true,
    autoplay: false,
    autoplaySpeed: 5000,
  };

  return (
    <div className="relative">
      <div className="case-slider">
        <div className="container">
          <h2 className="case-slider__heading">AI-SME use cases</h2>
          <Slider {...settings}>
            <div className="case-slider__slide">
              <div className="case-slider__content">
                <img src={tabImage} alt="tabImage" />
                <div className="case-slider__dialog ">
                  <h2 className="case-slider__title">Small Businesses</h2>
                  <p className="case-slider__description">
                    Small Businesses are using AI to decrease cost, increase
                    revenue while preventing from becoming AI Obsolete
                  </p>
                  <Link className="case-slider__button" to="#">
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
            <div className="case-slider__slide ">
              <div className="case-slider__content ">
                <img src={tabImage} alt="tabImage" />
                <div className="case-slider__dialog ">
                  <h2 className="case-slider__title">Mid-size companies</h2>
                  <p className="case-slider__description">
                    Mid-size companies are leveraging AI to keep up with large
                    enterprises and cope with digital AI transformation
                  </p>
                  <Link className="case-slider__button" to="#">
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
            <div className="case-slider__slide ">
              <div className="case-slider__content ">
                <img src={tabImage} alt="tabImage" />
                <div className="case-slider__dialog ">
                  <h2 className="case-slider__title">Enterprises</h2>
                  <p className="case-slider__description">
                    Enterprises are employing AI innovations to digitize
                    business models, processes and research
                  </p>
                  <Link className="case-slider__button" to="#">
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};
export default CaseSlider;
