import React from "react";

import { ANIM_DELAY } from "../../constants/constants";

import arrowdown from "../../images/arrow-down.png";

const WorkSection = () => {
  return (
    <section className="workSection">
      <div className="container">
        <div
          className="workSection__sub"
          data-sal="zoom-out"
          data-sal-delay={ANIM_DELAY}
          data-sal-easing="ease"
        >
          <h2 className="workSection__sub-title">See how it works</h2>
          <p className="workSection__sub-description">
            See how an SME can shape your AI vision, strategy and operations
          </p>
          <img src={arrowdown} alt="arrowdown" />
        </div>
      </div>
    </section>
  );
};
export default WorkSection;
